// External Dependencies
import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

// Internal Dependencies
import useInterval from '../../../utils/hooks/useInterval';

// Local Dependencies
import CountdownElement from './CountdownElement';

const getDateInSeconds = (date) => Math.floor(date.getTime() / 1000);

const getSecondsUntil = (deadline) => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60;

  const deadlineInSeconds = getDateInSeconds(new Date(deadline));
  const nowInSeconds = getDateInSeconds(new Date()) - timezoneOffset;

  return deadlineInSeconds - nowInSeconds;
};

const convertSecondsIntoCounterDisplay = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const hoursRemaining = hours % 24;
  const minutesRemaining = minutes % 60;
  const secondsRemaining = seconds % 60;

  return {
    days,
    hours: hoursRemaining,
    minutes: minutesRemaining,
    seconds: secondsRemaining,
  };
};

// Component Definition
const Countdown = ({
  deadline,
}) => {
  const [secondsUntilDeadline, setSecondsUntilDeadline] = useState(
    convertSecondsIntoCounterDisplay(getSecondsUntil(deadline)),
  );

  const updateSecondUntilDeadline = useCallback(() => {
    setSecondsUntilDeadline(convertSecondsIntoCounterDisplay(getSecondsUntil(deadline)));
  }, []);

  useInterval(updateSecondUntilDeadline, 1_000);

  return (
    <Box
      display="flex"
      justifyContent="center"
    >
      <CountdownElement
        unit="days"
        value={secondsUntilDeadline.days}
      />
      <CountdownElement
        unit="hours"
        value={secondsUntilDeadline.hours}
      />
      <CountdownElement
        unit="minutes"
        value={secondsUntilDeadline.minutes}
      />
      <CountdownElement
        unit="seconds"
        value={secondsUntilDeadline.seconds}
      />
    </Box>
  );
};

Countdown.propTypes = {
  deadline: PropTypes.string.isRequired, // 'YYYY-MM-DD'
};

export default Countdown;
