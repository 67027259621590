// External Dependencies
import { Link } from 'gatsby';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

// Internal Dependencies
import Countdown from '../components/shared/Countdown';
import CurveSection from '../components/shared/CurveSection';
import HeaderCurve from '../components/HeaderCurve';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import PromotionFinePrint from '../components/PromotionFinePrint';

// Component definition
const Switch = () => (
  <Layout variant="curve">
    <Meta title="Switch" />

    <HeaderCurve
      subtitle="Purchase a one-year license and get 6 months for free!*"
      title="Switch to Presto, get 6 months free"
    />

    <div className="switch" id="main">
      <CurveSection
        className="main"
        id="content"
      >
        <Container maxWidth="md">
          <Card className="switch-card" variant="outlined">
            <CardHeader title="Presto Change-O!" />

            <CardContent>
              <Typography paragraph variant="body2">
                You read that correctly! If your district or organization needs
                to switch from your current system,
                our team is here to help. We are offering a
                special promotion to help you transition to Presto Assistant.
              </Typography>

              <Typography paragraph variant="body2">
                Our team will walk you through the simple process to switch to Presto Assistant.
                Set up your district or organization in minutes and start exploring.
                Send your exported data from your prior system through our platform,
                and we will take care of importing the data into Presto Assistant for you!
              </Typography>

              <Typography variant="body2">
                Follow these steps to take advantage of this offer:
              </Typography>

              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className="switch-list-item-avatar">1</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Create a new District account.*" />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar className="switch-list-item-avatar">2</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Commit to purchase a license for Presto Assistant in 2024."
                  />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar className="switch-list-item-avatar">3</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Get 6 months for free!" />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Container>

        <Box className="panel">
          <Link
            className="transparent-button"
            data-variant="transport"
            to="/promotion"
          >
            Get Started
          </Link>
        </Box>

        <Box className="countdown-wrapper" textAlign="center">
          <Typography paragraph variant="h6">
            Promotion ends on August 31, 2024. Don&apos;t miss out!
          </Typography>

          <Countdown deadline="2024-08-31" />
        </Box>
      </CurveSection>

      <Container maxWidth="md">
        <Card
          className="switch-card"
          data-variant="outlined"
          variant="outlined"
        >
          <CardContent>
            <PromotionFinePrint />
          </CardContent>
        </Card>
      </Container>
    </div>
  </Layout>
);

export default Switch;
