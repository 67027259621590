// External Dependencies
import React from 'react';
import Typography from '@mui/material/Typography';

// This is a text-only component that can be used in different ways, depending on context

// Component Definition
const PromotionFinePrint = () => {
  return (
    <>
      <Typography paragraph variant="body2">
        * The fine print
      </Typography>

      <Typography paragraph variant="body2">
        This offer is only valid for school districts who have never purchased
        a Presto Assistant license.
        This offer applies for all organizations in your district.
      </Typography>

      <Typography paragraph variant="body2">
        The Presto Assistant team will issue a quote for your district.
        Your complimentary license(s) will become available once the quote is accepted.
      </Typography>

      <Typography variant="body2">
        Offer is valid until August 31, 2024.
      </Typography>
    </>
  );
};

export default PromotionFinePrint;
