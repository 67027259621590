// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';

// Local Variables
const zeroPadStart = (number) => String(number).padStart(2, '0');

// Component Definition
const CountdownElement = ({
  unit,
  value,
}) => (
  <Card className="countdown-element">
    <p>
      {zeroPadStart(value)}
    </p>

    <p>
      {unit}
    </p>
  </Card>
);

CountdownElement.propTypes = {
  unit: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default CountdownElement;
